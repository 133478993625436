.modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(32,32,71, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .modalContent {
    padding: 40px 24px 24px;
    background: #FFFFFF;
    border-radius: 10px;
    max-width: 510px;
    overflow: auto;
    transform: scale(0.5);
    transition: 0.4s all;

    .closeIcon {
      width: 12px;
      height: 12px;
      position: absolute;
      z-index: 25;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    &.active {
      transform: scale(1);
    }
  }
}

@media (max-width: 1024px) {
  .modal {
    .modalContent {
      padding: 40px 16px 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .closeIcon {
        width: 12px;
        height: 12px;
        position: absolute;
        z-index: 25;
        top: 40px;
        right: 20px;
      }
    }
  }
}
