.telephoneBtn {
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #3AB449;
  transition: .2s;

  &:hover {
    color: #51CE24;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .infoBlock {
    display: flex;
    flex-direction: column;

    .infoTitle {
      font-weight: 500;
      font-size: 32px;
      line-height: 48px;

      color: #202047;
      margin-bottom: 20px;
    }

    .infoRow {
      margin-bottom: 20px;

      .infoRowTitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #8F8FA8;
      }

      .infoRowDescription {
        margin-left: 20px;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #202047;
      }

      &.telephoneBlock {
        .notPhone {
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          color: #202047;
          margin-left: 20px;
          margin-right: 24px;
        }

        .telephoneRow {
          display: flex;
          align-items: center;
          margin-top: 12px;

          .telephone {
            font-weight: 400;
            font-size: 32px;
            line-height: 48px;
            color: #202047;
            margin-right: 24px;
          }

          .inputContainer {
            display: flex;
            justify-content: space-between;

            .input {
              border: 1px solid #5AB43A;
              border-radius: 5px;
              width: 100%;
              margin-right: 8px;
              font-weight: 400;
              font-size: 18px;
              line-height: 28px;
              color: #202047;
              outline: 0 none;
              transition: .3s;

              &:focus {
                border: 1px solid #FF5200;
              }
            }

            button {
              font-weight: 500;
              font-size: 18px;
              line-height: 28px;
              cursor: pointer;
              transition: .3ms;
              border-radius: 5px;
            }

            .saveButton {
              color: #FFFFFF;
              padding: 10px 32.5px;
              background: #5AB43A;
              border: none;

              &:hover {
                background: #51CE24;
              }

              &:disabled {
                opacity: 0.4;
              }
            }

            .cancelButton {
              color: #3AB449;
              background: none;
              border: 1px solid #DDDDE5;
              padding: 12px 26px;
              margin-left: 12px;
            }
          }

          .modalInputError {
            margin-top: 5px;
            font-weight: 300;
            font-size: 14px;
            line-height: 21px;

            color: #F10000;
          }
        }
      }
    }
  }

  .modalContainer {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
      margin-bottom: 23px;
    }

    color: #202047;

    .buttonsBlock {
      align-self: flex-end;
      margin-top: 32px;

      .outlinedButton {
        font-size: 16px;
        line-height: 24px;
        color: #3AB449;
        border: none;
        padding: 0;
        background: none;
        margin-right: 76px;
        margin-left: 68px;
        cursor: pointer;
        transition: .3s;

        &:hover {
          color: #51CE24;
        }
      }

      .fulfilledButton {
        font-size: 18px;
        line-height: 28px;
        color: #FFFFFF;
        background: #5AB43A;
        border-radius: 5px;
        padding: 10px 76.5px;
        border: none;
        cursor: pointer;
        transition: .3s;

        &:hover {
          background: #51CE24;
        }
      }
    }
  }

  .card {
    width: 420px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #DDDDE5;
    border-radius: 10px;

    .cardTitle {
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;

      color: #202047;
    }

    .cardInfo {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: #202047;

      .cardImage {
        width: 16px;
        height: 16px;
        margin-right: 16px;
      }
    }

    .cash {
      margin-top: 16px;
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;

      color: #FF5200;
    }

    form {
      width: 100%;

      .defaultBtn {
        width: 100%;
        margin-top: 12px;
        padding: 10px;
        background: #5AB43A;
        border-radius: 5px;
        font-size: 18px;
        line-height: 27px;
        color: #FFFFFF;
        border: none;
        transition: .2s;
        cursor: pointer;

        &:hover {
          background: #51CE24;
        }

        &:disabled {
          opacity: 0.5;
        }
      }
    }


    .payBtn {
      width: 100%;
      padding: 10px;
      background: #FFFFFF;
      border: 1px solid #DDDDE5;
      border-radius: 8px;
      font-size: 16px;
      line-height: 24px;
      color: #202047;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 12px;
      cursor: pointer;
      transition: .3s;

      &:hover {
        background: #F9FDF7;
        color: #5AB43A;
      }

      &:disabled {
        background: #F6F6FC;
        border: 1px solid rgba(221, 221, 229, 0.5);
        border-radius: 8px;
        color: #B5B5C5;
        cursor: inherit;

        img {
          opacity: 0.4;
        }
      }

      span {
        margin-left: 16px;
      }

      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  .modal {
    .modalTitle {
      font-size: 24px;
      line-height: 36px;
      margin: 0;
    }

    .modalInputPlaceholder {
      font-weight: 300;
      font-size: 18px;
      line-height: 27px;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .modalInputContainer {
      display: flex;
      justify-content: space-between;

      .modalInput {
        border: 1px solid #D5D5E9;
        border-radius: 5px;
        width: 100%;
        margin-right: 20px;
        height: 47px;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
      }

      .modalSaveButton {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #FFFFFF;
        padding: 10px 30px;
        background: #5AB43A;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        transition: .2ms;

        &:hover {
          background: #51CE24;
        }

        &.disabled {
          background: #D5D5E9;
        }
      }
    }

    .modalDescription {
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      margin: 20px 0 0;
      color: #8F8FA8;
    }

    .modalNotesContainer {
      display: flex;
      align-items: flex-start;
      margin-top: 20px;

      .modalNotesIcon {
        margin-top: 9px;
        margin-right: 5px;
        width: 13px;
        height: 13px;
      }

      .modalNotes {
        margin-top: 5px;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;

        color: #8F8FA8;

        span {
          color: #FF5200;
        }
      }
    }
  }

  .notification {
    position: fixed;
    left: 50%;
    top: 10px;
    padding: 6px 16px;
    transform: translate(-50%);

    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-radius: 4px;
    border: 1px solid #FF5200;

    .notificationText {
      font-size: 16px;
      font-weight: 300;
      margin-right: 20px;
    }

    img {
      cursor: pointer;
    }
  }
}

@media (max-width: 1024px) {
  .infoContainer {
    .infoBlock {
      .infoTitle {
        font-size: 24px;
        line-height: 36px;
      }

      .infoRow {
        font-size: 16px;
        line-height: 24px;

        &:nth-child(3) {
          display: flex;
          flex-direction: column;

          .infoRowDescription {
            margin-left: 0;
            margin-top: 8px;
          }
        }

        &.telephoneBlock {
          .notPhone {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-right: 16px;
          }

          .telephoneRow {
            margin-top: 12px;

            .telephone {
              font-size: 24px;
              line-height: 36px;
              margin-right: 16px;
            }

            .inputContainer {
              flex-direction: column;
              width: 100%;

              .input {
                width: calc(100% - 7px);
                margin-right: 0;
                height: 48px;
              }

              .saveButton {
                margin-top: 12px;
                margin-bottom: 12px;
              }

              .cancelButton {
                margin-left: 0;
              }
            }
          }
        }
      }
    }

    .modalContainer {
      .buttonsBlock {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        align-items: center;

        .outlinedButton {
          margin: 22px 0 0;
        }

        .fulfilledButton {
          width: 100%;
          padding: 10px;
        }
      }
    }

    .card {
      width: 296px;
      padding: 24px 16px;
    }
  }
}
