.headerBlock {
  width: 100%;
  background-color: #fff;
  color: #202047;

  .wrapper {
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .headerNav {
      display: flex;
      align-items: center;

      .headerNavItem {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        transition: 0.3s;

        &:nth-child(2) {
          margin-right: 48px;
          margin-left: 48px;
        }

        &:hover {
          color: #FF5200;
        }

        &Active {
          color: #FF5200;

          text-decoration: underline;
          text-underline-offset: 42px;
          text-decoration-thickness: 2px;

          &:nth-child(2) {
            margin-right: 48px;
            margin-left: 48px;
          }

        }

      }
    }

.mobileNav {
      display: none;
    }
  }
}

@media (max-width: 1024px) {
  .headerBlock {
    .wrapper {
      padding: 12px 20px 12px 16px;

      .headerNav {
        display: none;
      }

      .logoutContainer {
        display: none;
      }

      .mobileNav {
        cursor: pointer;
        display: block;
      }
    }
  }
}


