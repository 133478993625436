.getMinutesBlock {
  .title {
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: #202047;
    margin-bottom: 28px;
  }

  .productsForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 590px;

    .productCard {
      padding: 20px 34px 20px 24px;
      max-width: 530px;
      border: 1px solid #DDDDE5;
      border-radius: 10px;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all .3s;

      &:hover {
        border: 1px solid #FF5200;
      }

      &.active {
        border: 1px solid #FF5200;
      }

      input[type=radio] {
        display: none;
      }

      label {
        cursor: pointer;
        position: relative;

        margin-right: 0;
        line-height: 18px;
        user-select: none;
        display: flex;
        align-items: center;

        &:before {
          box-sizing: border-box;
          margin-right: 24px;
          content: "";
          display: inline-block;
          width: 24px;
          height: 24px;
          opacity: 0.5;
          border: 1px solid #8F8FA8;
          border-radius: 50%;
          transition: all .3s;
        }

        .productInfo {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .productTitle {
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;

            color: #202047;
          }

          .productDiscount {
            background: #FFEEE5;
            border-radius: 100px;
            padding: 4px 12px;
            border: none;
            margin-top: 8px;

            font-weight: 500;
            font-size: 14px;
            line-height: 20px;

            color: #FF5200;
          }
        }
      }

      input[type=radio]:checked + label:before {
        opacity: 1;
        border: none;
        background: url(./../../assets/acceptIcon.svg) center no-repeat;
        background-size: cover;
      }

      .productsPrice {
        .oldPrice {
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;

          text-decoration-line: line-through;
        }

        .newPrice {
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;

          color: #202047;
          margin-left: 8px;
        }
      }
    }

    .paymentButton {
      margin-top: 20px;
      align-self: flex-end;
    }

    .defaultBtn {
      max-width: 310px;
      margin-top: 12px;
      padding: 10px 63px;
      background: #5AB43A;
      border-radius: 5px;
      font-size: 18px;
      line-height: 27px;
      color: #FFFFFF;
      border: none;
      transition: .2s;
      cursor: pointer;

      &:hover {
        background: #51CE24;
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }
}

@media (max-width: 1024px) {
  .getMinutesBlock {
    .title {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 20px;
    }

    .productsForm {
      .productCard {
        margin-bottom: 12px;
        padding: 16px;

        label {
          &:before {
            margin-right: 20px;
          }
        }

        .productsPrice {
          .newPrice {
            margin-left: 0;
          }
        }
      }

      .productsPrice {
        display: flex;
        flex-direction: column;

        .oldPrice {
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;

          text-decoration-line: line-through;
        }

        .newPrice {
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;

          color: #202047;
          margin-left: 8px;
        }
      }
    }

    .paymentButton {
      margin-top: 20px;
      align-self: flex-end !important;
    }
  }
}

