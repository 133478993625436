.logoContainer {
  color: #202047;
  display: flex;
  align-items: center;

  .logo {
    width: 42px;
    height: 42px;
  }

  .logoText {
    font-size: 20px;
    line-height: 30px;

    span {
      font-weight: 300;
    }
  }

}

@media (max-width: 450px) {
  .logoContainer {
    .logo {
      width: 30px;
      height: 20px;
    }

    .logoText {
      font-size: 18px;
      line-height: 27px;
    }
  }
}



