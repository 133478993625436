.loginContainer {
  display: flex;
  justify-content: center;
  padding: 85px 16px;


  .loginBlock {
    background-color: #fff;
    border: 1px solid #EAEAEF;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 680px;


    .loginTitle {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      text-align: center;

      color: #000000;

      margin-top: 144px;
      margin-bottom: 20px;
    }

    .loginSubtitle {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      text-align: center;

      margin-bottom: 40px;

      color: #202047;
    }

    .loginButton {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #FFFFFF;
      padding: 4px 16px 4px 4px;
      background: #346EF1;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.084), 0 1px 1px rgba(0, 0, 0, 0.168);
      border-radius: 2px;
      border: none;
      cursor: pointer;

      img {
        width: 52px;
        height: 52px;
        margin-right: 15px;
      }
    }
  }
}

@media (max-width: 520px) {
  .loginContainer {
    padding-top: 60px;
    padding-bottom: 60px;

    .loginBlock {
      padding-right: 16px;
      padding-left: 16px;

      .loginTitle {
        margin-top: 77px;
        margin-bottom: 20px;
      }
    }
  }
}


