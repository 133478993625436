.loader {
  width: 100vw;
  height: 100vh;
  background-color: rgba(28, 28, 48, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;

  .loaderContent {
    pointer-events: none;
    .dots {
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background-color: #8F8FA8;
      color: #8F8FA8;
      animation: dotFlashing 1s infinite linear alternate;
      animation-delay: .5s;

      &::before, &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
      }

      &::before {
        left: -30px;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background-color: #8F8FA8;
        color: #8F8FA8;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 0s;
      }

      &::after {
        left: 30px;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background-color: #8F8FA8;
        color: #8F8FA8;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 1s;
      }

      @keyframes dotFlashing {
        0% {
          background-color: #8F8FA8;
        }
        50%,
        100% {
          background-color: #ebe6ff;
        }
      }
    }
  }
}




