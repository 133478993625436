.userInfoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .creditInfoBlock {
    display: flex;
    align-items: baseline;

    .creditTitle {
      font-weight: 300;
      font-size: 24px;
      line-height: 36px;
      color: #202047;

      span {
        font-weight: 500;
      }
    }

    .textBtn {
      margin-left: 16px;
      cursor: pointer;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      border: none;
      background-color: inherit;
      padding: 0;

      color: #FF5200;

      transition: color .3s;

      &:hover {
        color: #CC4100;
      }
    }
  }

  .userIdsBlock {
    display: flex;
    width: 690px;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 18px;
    line-height: 27px;

    .userIdTitle {
      color: #8F8FA8;

      span {
        margin-left: 20px;
        font-weight: 300;
        color: #8F8FA8;
      }
    }
  }
}


@media (max-width: 1024px) {
  .userInfoContainer {
    .creditInfoBlock {
      .creditTitle {
        font-size: 18px;
        line-height: 27px;
      }

      .textBtn {
        padding: 6px 12px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        background: #FF5200;
        border-radius: 4px;

        color: #FFFFFF;
        transition: .3s;

        &:hover {
          color: #FFFFFF;
          background-color: #CC4100;
        }
      }
    }

    .userIdsBlock {
      display: none;
    }
  }
}
