.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;

    color: #202047;
  }

  .description {
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;

    color: #202047;
  }

  .icon {
    width: 60px;
    height: 60px;
  }

  .button {
    width: 372px;
    padding: 10px;
    background: #5AB43A;
    border-radius: 5px;
    border: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    transition: .3s;
    cursor: pointer;
    &:hover {
      background: #51CE24;
    }
  }
}

@media (max-width: 1024px) {
  .button{
    width: 296px;
  }
}


