.logoutContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #3AB449;
  fill: #3AB449;
  transition: 0.3s;

  .logoutText {
    font-size: 16px;
    line-height: 24px;
    padding-right: 16px;
  }

  .logoutIcon {
    width: 23px;
    height: 23px;
  }

  &:hover {
    fill: #51CE24;
    color: #51CE24;
  }
}



