.mainContent {
  margin-top: 32px;
  padding-right: 30px;
  padding-left: 30px;
  .mainContentWrapper {
    background-color: #fff;
    border: 1px solid #EAEAEF;
    margin-top: 34px;
    padding: 40px;
  }
}

@media (max-width: 1024px) {
  .mainContent {
    margin-top: 20px;
    padding-right: 16px;
    padding-left: 16px;

    .mainContentWrapper {
      padding: 24px 16px;
      margin-top: 24px;
    }
  }
}
