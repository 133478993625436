.menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 55px;
  left: 0;
  transform: translateX(calc(100% + 287px));
  transition: all .2s;
  z-index: 1000;

  &Active {
    transform: translateX(0);
  }

  .overlay {
    width: 100vw;
    height: 100vh;
    right: 287px;
    background-color: rgba(32, 32, 71, 0.8);
    position: absolute;
  }

  .menuContent {
    position: absolute;
    padding: 48px 24px 40px;
    display: flex;
    flex-direction: column;
    right: 0;
    width: 287px;
    height: 100%;
    min-height: 100%;
    background-color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #202047;

    transition: all .4s;

    .main {
      flex: 1 1 auto;

      .menuItems {
        display: flex;
        flex-direction: column;
        .menuItem {
          margin-bottom: 32px;
        }
      }
    }

    .footer {
      height: 318px;
      margin-bottom: 40px;

      .menuInfo {
        margin-bottom: 32px;
        .infoBlock {
          margin-bottom: 16px;
          display: flex;
          flex-direction: column;

          .infoTitle {
            color: #8F8FA8;
          }
          .infoDescription {
            font-weight: 400;
          }
        }
      }
    }
  }


}
