.headerNavItem {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #FF5200;
  }

  &Active {
    color: #FF5200;
  }
}







