@import "./../../utils/styles/mixins";

.bannerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .bannerTitle {
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 16px;
    color: #202047;
  }

  .bannerDescription {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 32px;

    color: #202047;
  }

  .bannerImage {
    width: 200px;
    height: 200px;
    margin-bottom: 32px;
  }

  .bannerDownloadBtn {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    transition: .3s;
    padding: 10px 33.5px;

    background: #5AB43A;
    border-radius: 5px;
    text-decoration: none;

    &:hover {
      background: #51CE24;
    }
  }
}

@media (max-width: 1024px) {
  .bannerContainer {
    align-items: inherit;
    .bannerTitle {
      font-size: 24px;
      line-height: 36px;
    }

    .bannerImage {
     display: none;
    }
    
    .bannerDownloadBtn {
      text-align: center;
    }
  }
}
